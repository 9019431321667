import { useRoute } from 'vue-router';
import SearchOffers from '@/fragments/SearchOffers';
import PartnerMenu from '@/components/Partners/PartnerMenu';
import partners from '@/content/Partners';
export default {
  name: 'PartnerPage',
  components: {
    SearchOffers,
    PartnerMenu
  },

  setup() {
    const route = useRoute();
    var partner = partners;
    partner = partner.filter(partner => partner.name === route.name);
    return {
      partner: partner[0]
    };
  },

  methods: {
    imageUrl(imageUrl) {
      return require('@/assets/partners/' + imageUrl);
    }

  }
};