import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-17de08a8"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "partner-page--wrapper",
  itemtype: "http://schema.org/Restaurant",
  itemscope: ""
};
const _hoisted_2 = {
  class: "partner-page--header"
};
const _hoisted_3 = ["src", "alt"];
const _hoisted_4 = {
  class: "partner-page--header--cuisines"
};
const _hoisted_5 = {
  itemprop: "name"
};
const _hoisted_6 = ["innerHTML"];
const _hoisted_7 = {
  class: "partner-page--body"
};
const _hoisted_8 = {
  class: "cta"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tag = _resolveComponent("el-tag");

  const _component_PartnerMenu = _resolveComponent("PartnerMenu");

  const _component_SearchOffers = _resolveComponent("SearchOffers");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, [_createElementVNode("img", {
    src: $options.imageUrl($setup.partner.image),
    alt: $setup.partner.name,
    class: "image",
    itemprop: "image"
  }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.partner.cuisines, cuisine => {
    return _openBlock(), _createBlock(_component_el_tag, {
      key: cuisine,
      class: "partner-page--header--cuisines",
      type: "info",
      itemprop: "servesCuisine"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(cuisine), 1)]),
      _: 2
    }, 1024);
  }), 128))])]), _createElementVNode("div", null, [_createElementVNode("h1", _hoisted_5, _toDisplayString($setup.partner.name), 1), _createElementVNode("h2", {
    itemprop: "description",
    innerHTML: $setup.partner.description
  }, null, 8, _hoisted_6)])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", null, [_createVNode(_component_PartnerMenu, {
    menu: $setup.partner.menu
  }, null, 8, ["menu"])]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_SearchOffers, {
    partner: $setup.partner.id
  }, null, 8, ["partner"])])])]);
}